<template>
  <div>
    <div style="margin: 20px 0 0 20px"><MyBreadcrumb></MyBreadcrumb></div>
    <div class="user-stat">
      <div class="user-stat-cont" v-for="item in dataList" :key="item.id">
        <div class="tit"><span class="tit_con" @click="goSite(item)">{{item.name}}(id:{{item.id}})</span></div>
        <div class="cont">
          <p v-for="sItem in item.users" :key="sItem.id" @click="goUser(sItem.id)">{{sItem.nickname}}(id:{{sItem.id}})</p>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "analysis.png",
  data(){
    return {
      dataList:[],
    }
  },
  created(){
    this.getData();
  },
  methods:{
    getData(){
      this.api.system.systemWsStat().then((res)=>{
        this.dataList = res.stats;

      })
    },
    goUser(data){
      this.$router.push({
        name: 'user/detail',
        params: {
          id: data,
          tab: 'index'
        }
      })
    },
    goSite(data){
      this.$router.push({
        name: 'site/detail',
        params: {
          id: data.id,
          tab: 'user'
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.user-stat{
  margin: 20px;
  padding:20px;
  background: #FFFFFF;
  box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
  border-radius: 4px;
  font-size: 14px;

  .user-stat-cont{
    border: 1px solid #dcdee2;

    .tit{
      padding: 0 20px;
      height: 40px;
      line-height: 40px;
      font-weight: bold;
      border-bottom: 1px solid #dcdee2;
    }
    .tit_con{
      cursor: pointer;
    }
    .cont{
      padding: 0 20px;
      margin-bottom: 10px;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      >p{
        margin:10px 10px 0px 0;
        border: 1px solid #dcdee2;
        border-radius: 4px;
        padding:0 15px;
        height: 38px;
        line-height: 38px;
        cursor: pointer;
      }
    }
  }
}
</style>
